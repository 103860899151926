import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout page={{ title: 404, url: '/', uri: '/' }}>
    <div className="section"></div>
    <div className="section content">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md-8">
            <h2 className="title">404 Error</h2>
          </div>
          <div className="col-12 col-md-8 text">
            <p>The page could not be found.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
